export const MAX_FIELD_LENGTH = 50

export const MIN_PROMO_CODE_LENGTH = 3

export const REFINEMENTS_MENU = { WIDTH: 320, HEIGHT: 400 }

export enum KEY_EVENT_MAP {
  BACKSPACE = '8',
  DELETE = '46',
}

export const MIN_SEARCH_QUERY_LENGTH = 3

export const TITLE_OPTIONS = [
  {label: 'Please select', value: ''},
  {label: 'Mrs', value: 'mrs'},
  {label: 'Miss', value: 'miss'},
  {label: 'Ms', value: 'ms'},
  {label: 'Mr', value: 'mr'},
  {label: 'Dr', value: 'dr'},
]

export const MIN_AGE_OF_CUSTOMER = 18

export const MAX_BONUS_CARD_TOPUP_AMOUNT = 1000
export const DEFAULT_BONUS_CARD_TOPUP_AMOUNT = 10

export const APPLE_APP_STORE_LINK = 'https://apps.apple.com/gb/app/iceland-bonus-card/id1545925055'
export const GOOGLE_PLAY_MARKET_LINK =
  'https://play.google.com/store/apps/details?id=com.iceland.android'
export const BONUS_CARD_PREFIX = '63320400'

export const MAX_BONUS_CARD_FIELD_LENGTH = 12
export const ALCOHOL_PRODUCT_CLASS = 'ALC'
export const CREDENTIAL_SCREENS = {
  LOGIN: 'login',
  RESET_PASSWORD: 'reset-password',
  TWO_FACTOR: 'two-factor'
} as const

export const DEFAULT_PRODUCTS_AMOUNT_TO_LOAD = 10
