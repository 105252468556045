import "app/components/algolia/autocomplete/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"app/components/algolia/autocomplete/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61WTW/bOBC951ewKQrEBwayLTuuigW2LdBeeuhlT8GioMWRRZQWVZKKlRb576WoD5OU5GSLhQ4CyMfhm5k3M7wlBH8lBfAv5FFUGv26QqgklLLikKDo3dXT1a1BfJaEMij0B6G1OFoQZark5DFBhSigx1lLdvsnZgWFOkEPRN5g/G25O91t96e4Wrwzu3shKUjcWK3UCJNZTMcCa1GGiIMH2FtSUxiX1L4yqMJyy4Edch0e4K5R67vhSdLvBymqgoboVTlp/96Ehew50H/tTRfO/xjOf2FKo/8laC6fI5HfrdFMFBqfJl1elwvfS5wKLmSCtCSFKok0Ke+tvq+0SMWx5KDB2j0xqvMELaPoTWMk41DjPVFMndcMhwMrHB195EDkh3MmLrvE/Iz4u8SJibnxdlPWSAnOaBjoFtjnfBmX9RQb9XCYl7UDTBKSaZAWm5rImggl6Prau6Lh0izYKGZCGm1KoYmGm3hDoRVvF727FlkKxTQz1hHZGycqDc+ojywmue3BXAd/SA7PsXuex7cl/al1FkdTyuhzFIXWerW9/vS++ZpN8QAy4+KUoJxRCoVvPclEWil8YjpnvYBqrHJCmxNDxnr80kJ6hXhyGcTymu6ab6K+4o5uu9o2GSybKA6QyIOYRnVxX7YZiDoBDixf4tVgpAtZkIaTn4aV6/jaTX+8DW5fu9DVvBY78nGnnrlOJXwisTtPMIds1ILAa+VyvjHbNpaRI+OPozYWqHDrqnC9axkPBdCp0icVTbCIXiqKxsQFTUxuj4pgYryYIq6kajZLwUw1S9/LJG+qJZgzM+Z4EKGEpJo9wJ8dHpplBz/lTPuVdzeaft5EGbWImR4Pi1Gw3doNaMYLX74SOGmc9JklGZNK4zRnnHrqtO+MsDjv7olkBCvgkGqgf11rWcH1eLiHA7M/v3OSpKHWmEIqJGkJmpMgOQu61tyVZ5v/xdrbe0o0wcSZ3liJSqaAmbEtgTJp7vlH8q+8MsO68200Bv8+GiRBKpUABSIFRTdHUuN+UGzNqFjYk/4z8OIzrk2vm7KM1UDbRYvfvS3rV+xYCqlJKxyEuoINl/uiDdfDun+acYYVZ2d2c844qM22a4XNM+cciPVmzNgRu7/11F0xels5TyfUpM78otFdm1U0MDjzauDtovsi225e6PxuG806f756txq5+bzpZbSKXdsTXk89Im2Q1NGT8EvOn13+DWB+RdXkDAAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var form = '_1dzttf40';
export var input = '_1dzttf44';
export var inputWrapper = '_1dzttf41';
export var inputWrapperPrefix = '_1dzttf42';
export var inputWrapperSuffix = '_1dzttf43';
export var item = '_1dzttf47';
export var itemText = '_1dzttf48';
export var loadingIndicator = '_1dzttf45';
export var source = '_1dzttf49';
export var submitButton = '_1dzttf46';