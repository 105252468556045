import React, {useEffect, useState} from 'react'
import {useMealDealsSelectionCount} from './use-meal-deals-selection-count'
import {Box, Button, Flex, Stack, Text} from '../../vanilla'
import {observer} from 'mobx-react-lite'
import {HitItem} from '../../components/algolia/instantsearch/hits'
import {useGlobalStore, useProductStore} from '../../store/hooks/useStore'
import {Hit} from '../../store/ProductStore'
import {transformHit} from '../../components/algolia/util/transformHit'

interface MealDealItemProps {
  index: number
  label: string
  req: string
  productsIds: string[]
  setCompleteness: (complete: boolean) => void
}

const ITEMS_IN_ROW = 5

const MealDealItemContainer: React.FC<MealDealItemProps> = ({
  index,
  label,
  req: reqProp,
  productsIds,
  setCompleteness,
}) => {
  const req = parseInt(reqProp, 10)
  const [itemsToShowCount, setItemsToShowCount] = useState(ITEMS_IN_ROW)
  const number = index + 1
  const selection = useMealDealsSelectionCount(productsIds)
  const productStore = useProductStore()
  const globalStore = useGlobalStore()

  // Grab all products to pass into HitItem and order by isAddToCartDisabled
  const products = productsIds
    .map((id) => productStore.productsById[id] as unknown as Hit)
    .sort((a, b) => Number(a.isAddToCartDisabled) - Number(b.isAddToCartDisabled))

  // Sets current custom site preferences for three badges in state
  const [pdpTopRightTileBadgeEnabled, setPdpTopRightTileBadgeEnabled] = useState(false)
  const [pdpMiddleRightTileBadgeEnabled, setPdpMiddleRightTileBadgeEnabled] = useState(false)
  const [pdpBottomRightTileBadgeEnabled, setPdpBottomRightTileBadgeEnabled] = useState(false)

  // Updates state with current custom site preferences for three badges
  useEffect(() => {
  setPdpTopRightTileBadgeEnabled(globalStore.badgesCustomPreferences?.topRightBadgeCustomPreference as boolean)
  setPdpMiddleRightTileBadgeEnabled(globalStore.badgesCustomPreferences?.middleRightBadgeCustomPreference as boolean)
  setPdpBottomRightTileBadgeEnabled(globalStore.badgesCustomPreferences?.bottomRightBadgeCustomPreference as boolean)
  }, [])

  useEffect(() => {
    setCompleteness(selection >= req)
  }, [selection, req])
  
  return (
    <Box id={`step-${number}`}>
      <Stack gap="24px">
        <Flex gap="8px" justifyContent="center" alignItems="center">
          <Flex
            alignItems="center"
            justifyItems="center"
            justifyContent="center"
            bg={selection >= req ? 'accent1' : 'accent3'}
            padding="8px"
            style={{ borderRadius: '100%', height: '65px', minWidth: '65px' }}
          >
            <Text color="white" textAlign="center" variant="heading5">
              {number}
            </Text>
          </Flex>
          <Stack gap="4px">
            <Box>
              <Text variant="heading5">
                Choose {req} {label}
              </Text>
            </Box>
            <Box>
              <Text variant="text3" color="gray400" lineHeight="none">
                {selection} of {req}{' '}
                {selection === 0
                  ? 'Add one to start'
                  : selection < req
                  ? 'Added'
                  : 'Well done, step completed!'}
              </Text>
            </Box>
          </Stack>
        </Flex>
        <Box 
          display ='grid'
          gridTemplateColumns={['1x','5x']}
          gap = '16px'
          justifyContent={'center'}
        >
          {products.slice(0, itemsToShowCount).map((hit) => {
            return <HitItem
            hit={transformHit(hit) as Hit}
            key={hit.id}
            isPLPTile={true}
            pdpTopRightTileBadgeEnabled={pdpTopRightTileBadgeEnabled}
            pdpMiddleRightTileBadgeEnabled={pdpMiddleRightTileBadgeEnabled}
            pdpBottomRightTileBadgeEnabled={pdpBottomRightTileBadgeEnabled}
            showNearPromo={false}
            />
          })}
        </Box>
        {productsIds?.length > itemsToShowCount ? (
          <Flex justifyContent="center">
            <Button
              variant="outlineDark"
              onClick={() => {
                if (itemsToShowCount < productsIds.length) {
                  setItemsToShowCount(itemsToShowCount + ITEMS_IN_ROW)
                }
              }}
            >
              Show More {label}
            </Button>
          </Flex>
        ) : null}
      </Stack>
    </Box>
  )
}

export const MealDealItem = observer(MealDealItemContainer)