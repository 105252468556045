import React, {ReactNode} from 'react'
import {Stack, Text, Box} from '../../../vanilla'
import {mobileNavigationMenuListItemRoot} from '../styles.css'
import {Link} from '../../link'

interface MobileNavLinksProps {
  mobileNavLinks: Array<{link: string; name: string; icon?: ReactNode}>
  onClose: () => void
}

export const MobileNavigationLinks = ({mobileNavLinks, onClose}: MobileNavLinksProps) => {
  return (
    <Stack as="ul" bg="white" spacing="0px">
      {mobileNavLinks.map(({link, icon, name}) => (
        <Stack
          as="li"
          direction="row"
          borderBottom="1px"
          justify="space-between"
          alignItems="center"
          width="full"
          style={{borderBottom: '1px solid #d8d8d8'}}
          key={name}
        >
          <Stack
            direction="row"
            padding="16px"
            alignItems="center"
            width="full"
            className={mobileNavigationMenuListItemRoot}
            to={link}
            as={Link}
          >
            <Box 
              minWidth="28px" 
              display="flex" 
              justifyContent="center"
            >
              {icon}
            </Box>
            <Text
              fontWeight="medium"
              variant="unstyled"
              lineHeight="shorter"
              as="span"
              style={{fontSize: 15}}
              onClick={onClose}
            >
              {name}
            </Text>
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
}
