import {observer} from 'mobx-react-lite'
import React, {useEffect, useState} from 'react'
import {Layout} from '../../components/layout'
import {BaseButton, Box, Container, Flex, Stack, Text} from '../../vanilla'
import {MealDealItem} from './meal-deals-item'
import {useMealDeals} from './use-meal-deals'
import {Link} from 'react-router-dom'
import {useMealDealsCompleteness} from './use-meal-deal-completeness'
import {CompletenessMessage} from './completeness-message'
import {
  stickyBannerContainer,
  scaleDownFadeOutKeyFrame,
  scaleUpFadeInKeyFrame,
} from '../../components/cms/sticky-banner/styles.css'
import {useGlobalStore, useProductStore, useContentStore} from '../../store/hooks/useStore'
import {Slot} from '../../components/cms/Slot'
import {SlotItem} from '../../types/cms'
import {Spinner} from '../../components/spinner'

const animationMap = {
  show: scaleUpFadeInKeyFrame,
  hide: scaleDownFadeOutKeyFrame,
} as const

const MealDealsContainer: React.FC = () => {
  const {mealDeals, status} = useMealDeals()
  const {complete, update} = useMealDealsCompleteness(mealDeals?.groups)
  const [animation, setAnimation] = useState<keyof typeof animationMap>('show')
  const cms = useContentStore()
  const mealDealPromoKey = mealDeals?.id
  const mealDealBanner = mealDealPromoKey ? cms.contentById[mealDealPromoKey] : null

  const {selectedPriceDayOffset, selectedRegion} = useGlobalStore()
  const {productsById, loading} = useProductStore()
  const redirectShoppingUrl ='/offers'
  const [isLoading, setIsLoading] = useState(true)
  
  // Hide spinner when mealDeals has loaded
   useEffect(() => {
    if (mealDeals?.c_isMealDealLanding && !loading && status === 'done') {
      setIsLoading(false)
    } else if (status === 'done' && (!mealDeals || mealDeals.c_isMealDealLanding !== true)) {
      setIsLoading(false)
    }
  }, [mealDeals, loading, status])

  useEffect(() => {
    if (complete) {
      setAnimation('show')
    } else {
      setAnimation('hide')
    }
  }, [complete])

  useEffect(() => {
    (async function () {
      if (mealDealPromoKey && !mealDealBanner) {
        await cms.fetchItemByKey(mealDealPromoKey)
      }
    })()
  }, [mealDealPromoKey, cms])

  if (
    isLoading 
    || (mealDeals?.c_isMealDealLanding === true && !(Object.keys(productsById).length > 0)) 
    || ['initial', 'loading'].includes(status)
  ) {
    return (
    <Flex justify="center" align="center" style={{minHeight: '200px'}}>
      <Spinner color="primary" />
    </Flex>
  )}

  const filteredProductsIds = (productsIds: string[]) => 
    productsIds.filter(id => {
    const product = productsById[id]
    return product && !(
      (selectedPriceDayOffset != null && product.matrixPrices?.[selectedPriceDayOffset]?.[selectedRegion] == null)
    )
  })

  const enoughProductsInEachGroup = mealDeals?.groups?.every(group => {
    const enabledProductsCount = filteredProductsIds(group.ids).filter(id => {
      const product = productsById[id]
      return product && !product.isAddToCartDisabled
    }).length
    return enabledProductsCount >= parseInt(group.req)
  })

  const isDealAvailable = Object.keys(productsById).length > 0 && enoughProductsInEachGroup && mealDeals?.groups?.some(group => {
    const filteredIds = filteredProductsIds(group.ids) 
    return filteredIds.length > parseInt(group.req)
  })
  
  if ((mealDeals == null && status === 'done') || mealDeals?.c_isMealDealLanding !== true || !isDealAvailable) {
    return (
      <Layout>
        <Container paddingX={['0px', '8px']} paddingY="16px">
          <Flex justifyContent="center">
            <Box bg="white" padding="32px" maxWidth="half">
              <Stack gap="16px">
                <Text variant="heading4">Deal Not Available</Text>
                <Text variant="text4">Sorry, this deal is no longer available.</Text>
                <Text variant="text4">
                  Don't worry, we have loads of other great deals available in your area!
                </Text>
                <BaseButton as={Link }to={redirectShoppingUrl}>
                  <Text variant="text4">See Other Deals</Text>
                </BaseButton>
              </Stack>
            </Box>
          </Flex>
        </Container>
      </Layout>
    )
  }

  return (
    <>
      <Layout>
        <Container paddingX={['0px', '8px']} paddingY="16px">
        <Box display={'block'} paddingBottom={'20px'}>
          {mealDealBanner && (
            <Slot
              data={mealDealBanner as SlotItem}
              deliveryKey={mealDealBanner._meta.deliveryKey}
            />
          )}
        </Box>
          <Stack gap="48px" id={mealDeals.id}>
            {complete ? <CompletenessMessage /> : null}
            {mealDeals.groups?.map?.((group, index) => (
              <MealDealItem
                index={index}
                key={`${mealDeals.id}-${group.label}`}
                label={group.label}
                productsIds={filteredProductsIds(group.ids)}
                req={group.req}
                setCompleteness={(complete) => {
                  update(index, complete)
                }}
              />
            ))}
            <Box>
              <Flex gap="16px" justifyContent="center">
                <BaseButton to="/basket" as={Link} variant="outlineDark">
                  <Text>View Basket</Text>
                </BaseButton>
                <BaseButton as={Link} to="/">
                  <Text>Continue Shopping</Text>
                </BaseButton>
              </Flex>
            </Box>
            {mealDeals.calloutMsg ? (
              <Box>
                <Text color="gray500">{mealDeals.calloutMsg}</Text>
              </Box>
            ) : null}
          </Stack>
        </Container>
      </Layout>
      {complete ? (
        <Box
          className={stickyBannerContainer}
          position="fixed"
          zIndex="sticky"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            animationName: animationMap[animation],
          }}
        >
          <CompletenessMessage
            onClose={() => {
              setAnimation('hide')
            }}
            showCloseIcon
          />
        </Box>
      ) : null}
    </>
  )
}

export const MealDeals = observer(MealDealsContainer)
