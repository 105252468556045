import React, {useEffect, useMemo, useRef} from 'react'
import useEinstein from '../../store/hooks/useEinstein'
import {useProductStore} from '../../store/hooks/useStore'
import {BoxProps, Container, Flex, Stack} from '../../vanilla'
import {HitItem} from '../algolia/instantsearch/hits'
import {Hit} from '../../store/ProductStore'
import * as styles from './styles.css'
import {Carousel} from '../carousel'
import {recommenderHit, recommenderItemSpacing} from './styles.css'
import {useHitsProductBadges} from '../algolia/util/useHitsProductBadges'
import {RecommendationsParams} from 'commerce-api'
import {Spinner} from '../spinner'
import {observer} from 'mobx-react'
import {useIntersectionObserver} from '../../hooks/use-intersection-observer'

export interface RecommenderProps {
  name?: string
  productId?: string | string[]
  recommender: string
  categoryId?: string
  containerProps?: BoxProps<'div'>
}

const RecommenderContainer = ({
  name,
  productId,
  recommender,
  categoryId,
  containerProps
}: RecommenderProps) => {
  const einstein = useEinstein()
  const {getRecommendations, recommendations, sendViewRecommendations, recoUUID, loading} = einstein
  const {productsById} = useProductStore()

  const ref = useRef(null)
  const isOnScreen = useIntersectionObserver(ref, {
    useOnce: true,
    // Offset for intersection track. Sometimes user  can view top part of the section on PDP,
    // but products are still no visible to him, so adding offset to ensure that user scrolled and products are visible,
    // only then change observer state.
    rootMargin: '-150px 0px -150px 0px',
  })

  const hits = useMemo(
    () =>
      recommendations?.length
        ? (
            recommendations.map((product) =>
              productsById[product.id] ? productsById[product.id] : null
            ) as unknown as Hit[]
          ).filter(Boolean)
        : [],
    [recommendations]
  )

  useHitsProductBadges(hits)
  
  useEffect(() => {
    const fetchRecommendations = async () => {
      let args
      if (productId) {
        args = {
          products: Array.isArray(productId) ? productId.map((id) => ({id})) : [{id: productId}],
        }
      } else if (categoryId) {
        args = {categories: [{id: categoryId}]}
      }
      if (recommender) {
        getRecommendations(recommender, args as RecommendationsParams)
      }
    }
    fetchRecommendations().catch(console.error)
  }, [productId, categoryId])

  /* Triggers Einstein events, when "recos" are loaded and section is on user screen */
  useEffect(() => {
    if (isOnScreen && recommendations.length && recoUUID) {
      sendViewRecommendations({
        recommenderName: recommender,
        __recoUUID: recoUUID,
        products: recommendations,
      })
    }
  }, [isOnScreen, recommendations])

  return (
    <Container paddingX={['12px', '32px']} position="relative" {...(containerProps || {})}>
      {recommendations?.length > 0 && (
        <Stack spacing="32px" ref={ref}>
          <Flex>
            <h2
              style={{
                width: '100%',
                textAlign: 'center',
                borderBottom: '1px solid #d8d8d8',
                lineHeight: '0px',
                margin: '20px 0px',
              }}
            >
              <span className={styles.recommenderTitle}>{name}</span>
            </h2>
          </Flex>
          <Stack gap={['20px', '28px']} position="relative">
            <Carousel
              loop={false}
              className="recommendations-carousel"
              itemClassName={recommenderItemSpacing}
              controlsPosition="bottom"
              controlConfig={{
                activeDotColor: 'gray800',
                showArrows: {mobile: false, desktop: true, tablet: true},
                arrowsColor: 'gray800',
              }}
              customResponsiveConfig={{
                desktop: {
                  breakpoint: {min: 1024, max: 99999},
                  items: 5,
                  slidesToSlide: 5,
                },
                tablet: {
                  breakpoint: {max: 1024, min: 780},
                  items: 4,
                  slidesToSlide: 4,
                },
                mobile: {
                  breakpoint: {max: 780, min: 480},
                  items: 2,
                  slidesToSlide: 2,
                },
                'mobile-xs': {
                  breakpoint: {max: 480, min: 0},
                  items: 1,
                  slidesToSlide: 1,
                  partialVisibilityGutter: 50,
                },
              }}
            >
              {recommendations.map((product, idx) => {
                const hit = productsById[product.id]
                return (
                  hit && (
                    <HitItem
                      einsteinRecoParams={{recoUUID: recoUUID!, recommender}}
                      hit={hit as unknown as Hit}
                      key={product.id}
                      className={recommenderHit}
                      isPLPTile={true}
                    />
                  )
                )
              })}
            </Carousel>
          </Stack>
        </Stack>
      )}
      {loading && (
        <Flex justify="center" align="center" style={{minHeight: '200px'}}>
          <Spinner color="primary" />
        </Flex>
      )}
    </Container>
  )
}

export const Recommender = observer(RecommenderContainer)
