import React from 'react'
import {SlotComponent} from '../../../types/ui'
import {AccordionList, ImageGroup} from '../../../types/cms'
import {Box, Container, Stack, Text} from '../../../vanilla'
import {twoColumnsLayout, secondaryColumn, primaryColumn} from './styles.css'
import {CmsImage} from '../cms-image'
import {ContentAccordion} from '../ContentAccordion'
import {AccordionNavigation} from './partials/accordion-navigation'
import {ContentText, ContentTextCmsContent} from '../ContentText'
import {DefaultContentBody} from 'dc-delivery-sdk-js'
import {ContactUsForms} from '../../contact-us-forms'

type ContentAccordion2ColumnLayoutComponent =
  | AccordionList
  | (ContentTextCmsContent & DefaultContentBody)

interface ContentAccordion2ColumnLayoutCmsContent {
  image?: ImageGroup
  title?: string
  accordionList: ContentAccordion2ColumnLayoutComponent[]
}

export const ContentAccordion2ColumnLayout: SlotComponent<
  ContentAccordion2ColumnLayoutCmsContent
> = ({content}) => {
  const {image, accordionList: componentList, title} = content

  const isAccordionComponent = (
    component: ContentAccordion2ColumnLayoutComponent
  ): component is AccordionList => {
    return component._meta.schema === componentNameToSchemaMap.ACCORDION
  }

  const isContentText = (
    component: ContentAccordion2ColumnLayoutComponent
  ): component is ContentTextCmsContent & DefaultContentBody => {
    return component._meta.schema === componentNameToSchemaMap.TEXT
  }

  const navigationList = mapAccordionListToNavigationList(
    componentList.filter(isAccordionComponent)
  )

  return (
    <Container className={twoColumnsLayout} paddingX="0px" flexWrap={['wrap', 'nowrap']}>
      <Stack className={secondaryColumn}>
        {image && <CmsImage maxWidth="full" images={image.images} />}
        <Stack bg="white" spacing="0px" width="full">
          {title && (
            <Box paddingX="28px" paddingY="16px" borderBottom="1px" borderColor="gray200">
              <Text variant="heading1">{title}</Text>
            </Box>
          )}
          <AccordionNavigation list={navigationList} />
        </Stack>
      </Stack>
      <Stack className={primaryColumn} spacing="24px">
        {componentList.map((cmsComponent, idx) => {
          if (isAccordionComponent(cmsComponent)) {
            return (
              <ContentAccordion
                key={cmsComponent.anchorID}
                content={cmsComponent}
                headingProps={{
                  color: 'gray800',
                  bg: 'white',
                  paddingX: '28px',
                  paddingY: '16px',
                }}
                accordionHeadingProps={{
                  bg: 'gray25',
                  color: 'gray800',
                  border: '1px',
                  borderTop: '0px',
                  borderColor: 'gray200',
                  borderBottom: '0px',
                  paddingRight: '16px',
                  backgroundColor: 'white',
                }}
                accordionContentProps={{
                  style: {
                    marginLeft: '0px!important',
                  },
                  borderLeft: '1px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  paddingX: '28px',
                  paddingY: '16px',
                  borderRight: '1px',
                  borderColor: 'gray200',
                  backgroundColor: 'white',
                }}
              />
            )
          }
          if (isContentText(cmsComponent)) {
            return (
              <Stack spacing="0px" key={cmsComponent._meta.deliveryId + idx} bg="white">
                <Box paddingX="20px" paddingY="24px" borderBottom="1px" borderColor="gray200">
                  <Text variant="unstyled" fontSize="lg" fontWeight="normal" lineHeight="shorter">
                    Most Frequently Asked Questions
                  </Text>
                </Box>
                <ContentText
                  content={cmsComponent}
                  containerProps={{
                    paddingX: '20px',
                    paddingY: '24px',
                  }}
                />
              </Stack>
            )
          }
        })}
        <ContactUsForms />
      </Stack>
    </Container>
  )
}

const componentNameToSchemaMap = {
  ACCORDION: 'https://www.iceland.co.uk/content/content-accordion.json',
  TEXT: 'https://www.iceland.co.uk/content/content-text.json',
} as const

const mapAccordionListToNavigationList = (accordionList: AccordionList[]) => {
  if (!accordionList?.length) return []

  return accordionList
    .filter((list) => list.anchorID)
    .map((list) => {
      return {
        title: list.heading,
        hrefLink: '#' + list.anchorID,
      }
    })
}
