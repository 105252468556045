import React from 'react'
import LazyLoad from 'react-lazyload'
import {SlotComponent} from '../../types/ui'
import {Recommender} from '../recommendations'
import {Stack} from '../../vanilla'
import {WelcomeBack} from '../welcome-back'
import {CTA} from '../../types/cms'

interface ProductCarouselProps {
  einstein: {
    recommenderId: string
  }
  showFavourites?: boolean
  title?: string
  subtitle?: string
  category?: {
    name: string
    id: string
  }
  cta: CTA
}

export const ProductCarousel: SlotComponent<ProductCarouselProps> = ({content}) => {
  if (!content) {
    return null
  }
  const {category, showFavourites, title, subtitle, einstein, cta} = content

  return (
    <Stack>
      {showFavourites ? (
        <WelcomeBack 
        cta={cta} 
        title={title} 
        subtitle={subtitle}
        />
      ) : (
        <Stack>
          {einstein && (
            <LazyLoad once>
              <Recommender
                name={title}
                recommender={einstein?.recommenderId}
                categoryId={category?.id}
              />
            </LazyLoad>
          )}
        </Stack>
      )}
    </Stack>
  )
}
