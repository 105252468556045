import React from 'react'
import {AccountIcon, BonusCardIcon, CopyIcon, HeartIcon} from '../../icons'
import {MobileNavigationLinks} from './mobile-navigation-links'

const mobileNavLinks = [
  {
    link: '/account/order-history',
    name: 'My orders',
    icon: <CopyIcon boxSize="20px" color="accent3" />,
  },
  {
    link: '/account/bonus-card',
    name: 'Bonus Card',
    icon: <BonusCardIcon boxSize="28px" color="accent3" />,
  },
  {
    link: '/account',
    name: 'Account',
    icon: <AccountIcon boxSize="20px" color="accent3" />,
  },
  {
    link: '/my-favourites',
    name: 'Favourites',
    icon: <HeartIcon boxSize="20px" color="accent3" />,
  },
]

export interface MobileNavigationLoggedLinksProps {
  onClose: () => void
}


export const MobileNavigationLoggedLinks = ({onClose}: MobileNavigationLoggedLinksProps) => {
  return <MobileNavigationLinks mobileNavLinks={mobileNavLinks} onClose={onClose}/>
}
