import React from 'react'
import {observer} from 'mobx-react-lite'
import {useCustomerStore, useProductStore} from '../../store/hooks/useStore'
import {Box, Container, Stack, Text} from '../../vanilla'
import {HitItem} from '../algolia/instantsearch/hits'
import {useHitsProductBadges} from '../algolia/util/useHitsProductBadges'
import {Carousel} from '../carousel'
import {favHit, favItemSpacing, welcomeBackLinkBtn, welcomeBackTitleLine} from './styles.css'
import {Hit, ProductModel} from '../../store/ProductStore'
import {CTAButton} from '../../vanilla/components/CTAButton'
import {CTA} from '../../types/cms'
import {useItemPrice} from '../algolia/util/useItemPrice'

interface WelcomeBackProps {
  cta?: CTA
  title?: string
  subtitle?: string
}

const WelcomeBackContainer = ({cta, title, subtitle}: WelcomeBackProps) => {
  const {isRegistered, wishlistHits} = useCustomerStore()
  const {productsById} = useProductStore()

  useHitsProductBadges(wishlistHits as unknown as Hit[])

  if (!isRegistered || !wishlistHits.length) return null

  const filteredHits = wishlistHits
    .filter(product => {
      const hit = productsById[product.id];
      if (!hit) return false; 

      const {displayPrice} = useItemPrice(hit as ProductModel);

      const isButtonDisabled = !displayPrice
        || hit.availableForDayAtStore === false
        || hit.isAddToCartDisabled === true;

      return !isButtonDisabled;
    })
    .slice(0, 10);
  
  return (
    <Box backgroundColor="gray50">
      <Container paddingY={['12px', '24px']}>
        <Stack spacing="40px">
          <Stack gap="2px" className={welcomeBackTitleLine} position="relative" align="flex-start">
            {title && (
              <Text
                as="h2"
                variant="heading7"
                color="accent1"
                align="center"
                zIndex="docked"
                paddingX="8px"
                position="relative"
                bg="gray50"
                alignSelf="center"
              >
                {title}
              </Text>
            )}
            {subtitle && (
              <Text as="h2" variant="heading7" align="center" color="gray800" alignSelf="center">
               {subtitle}
              </Text>
            )}
          </Stack>

          <Stack gap={['20px', '28px']} position="relative">
            <Carousel
              loop={false}
              className="recommendations-carousel"
              itemClassName={favItemSpacing}
              controlsPosition="bottom"
              controlConfig={{
                activeDotColor: 'gray800',
                showArrows: {mobile: false, desktop: true, tablet: true},
                arrowsColor: 'gray800',
              }}
              customResponsiveConfig={{
                desktop: {
                  breakpoint: {min: 1024, max: 99999},
                  items: 5,
                  slidesToSlide: 5,
                },
                tablet: {
                  breakpoint: {max: 1024, min: 780},
                  items: 4,
                  slidesToSlide: 4,
                },
                mobile: {
                  breakpoint: {max: 780, min: 480},
                  items: 2,
                  slidesToSlide: 2,
                },
                'mobile-xs': {
                  breakpoint: {max: 480, min: 0},
                  items: 1,
                  slidesToSlide: 1,
                  partialVisibilityGutter: 50,
                },
              }}
            >
              {filteredHits.map((hit: Hit) => {
                return (
                  hit && (
                    <HitItem
                      hit={hit}
                      isPLPTile={true}
                      key={`wishlist-${hit.id}`}
                      className={favHit}
                    />
                  )
                )
              })}
            </Carousel>
            {cta?.ctaText && <CTAButton cta={cta} extraClassName={welcomeBackLinkBtn} alignSelf="center"></CTAButton>}
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export const WelcomeBack = observer(WelcomeBackContainer)
