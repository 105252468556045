import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Box, Button, Flex, Text, Stack} from '../../vanilla'
import {HeartFilledIcon, HeartIcon} from '../icons'
import {promotionModalTopRightBadges, promotionProductTile} from './styles.css'
import useWishlistItem from '../../store/hooks/useWishlistItem'
import {
  useBasketStore,
  useProductStore,
  useCustomerStore,
  useOrderStore,
  useGlobalStore,
} from '../../store/hooks/useStore'
import {useItemPrice} from '../algolia/util/useItemPrice'
import {ReviewStars} from '../review-stars'
import {Hit, ProductModel} from '../../store/ProductStore'
import useEinstein from '../../store/hooks/useEinstein'
import {Price} from '../price'
import UpdateCartItemQuantity from '../update-cart-quantity'
import {ATBButton} from '../add-to-basket-button'
import {transformHit} from '../algolia/util/transformHit'
import {useBasketItem} from '../../store/hooks/useBasketItem'
import {useAddToCartTracking} from '../../analytics/click-event-tracking'
import {CART_LOCATION} from '../../analytics/utils'
import {useSlotExpiration} from '../../store/hooks/useSlotExpiration'
import {formatProductName, getDisabledButtonText} from '../../utils/utils'
import useNavigation from '../../hooks/use-navigation'
import {ModalNavigationProps} from '../../types/ui'
import {ProductImage} from '../product-detail/product-image'
import {TopRightProductBadgeGroup} from '../top-right-product-badges/top-right-product-badge-group'

interface ProductTileProps {
  product: ProductModel
  productId: string
  onToggle: () => void
  showAddToBasket?: boolean
  quantity?: number
  modalNavigationProps?: ModalNavigationProps
}

const ProductTile: React.FC<Omit<ProductTileProps, 'productId'>> = observer(
  ({product, showAddToBasket, quantity: quantityProp, onToggle, modalNavigationProps}) => {
    const basketStore = useBasketStore()
    const globalStore = useGlobalStore()
    const customerStore = useCustomerStore()
    const {editMode} = useOrderStore()
    const navigate = useNavigation()
    const {isReservationExpired, handleEditReservationExpired} = useSlotExpiration()
    const {sendViewProduct} = useEinstein()
    const {displayPrice, promotionPrice} = useItemPrice(product)
    const {handleAddWishlistItem, isInWishlist, handleRemoveWishlistItem} = useWishlistItem(
      product.id,
    )
    const hit = transformHit(product as unknown as Hit) as Hit
    const {quantity} = useBasketItem(hit.id)
    const [clickedQuantity, setClickedQuantity] = useState(0)
    const sendAddToCartData = useAddToCartTracking(basketStore.basket)
    
    
    useEffect(() => {
      setClickedQuantity(quantity)
    }, [basketStore.count])

    // @todo format the algolia data to be consistent across PLP and PDP - happens in price component too
    const img = product?.image_groups?.[0]?.images?.[0] || product.imageGroups?.[0]?.images?.[0]

    const addToBasket = (e: React.MouseEvent | React.TouchEvent) => {
      e.preventDefault()
      e.stopPropagation()

      if (editMode && isReservationExpired()) {
        // If in edit mode and slot has expired send to order history and don't add to basket
        handleEditReservationExpired()
      } else {
        basketStore.addProductToUpdate({product: hit, quantity: 1})
        setClickedQuantity(1)
        customerStore.setLastActiveToNow()
      }
    }

    const productLink = `/p/${formatProductName(product?.name)}/${product?.id}.html`

  const disabledButtonText = (disabledStatus: string) => {
    if (disabledStatus === 'coming-soon') return 'Coming Soon'
    if (disabledStatus === 'in-store-only') return 'In Store Only'
    return 'Out of Stock'
}

    return (
      <Box
        bg="white"
        borderBottom={['1px', '0px']}
        borderColor="gray200"
        className={promotionProductTile}
        display="flex"
        flexWrap="wrap"
        height="full"
        position="relative"
        width="full"
      >
        <Button
          variant="unstyled"
          position="absolute"
          right="0px"
          top="0px"
          onClick={() => (isInWishlist ? handleRemoveWishlistItem() : handleAddWishlistItem())}
        >
          {isInWishlist ? (
            <HeartFilledIcon color="accent0" boxSize="20px" />
          ) : (
            <HeartIcon color={{default: 'black', hover: 'accent0'}} boxSize="20px" />
          )}
        </Button>
        <Box
          flexShrink="0"
          position="relative"
          ratio="1"
          style={{maxWidth: 80, flexBasis: 80}}
          width="full"
          onClick={() => {
            if (typeof modalNavigationProps !== 'undefined') {
              onToggle()
              modalNavigationProps.history.push(productLink)
              modalNavigationProps.searchRef?.current?.setQuery('')
            } else {
              navigate(productLink)
            }

            sendViewProduct({product: {id: product.id, sku: ''}})
          }}
        >
          <ProductImage 
            image={img}
            imageAlt={img?.alt}
            isThumbnail={false}
            inDrawer={false}
            display="block"
            draggable={false}
            height="auto"
            loading="lazy"
            style={{maxHeight: 80, objectFit: 'contain'}}
            width="full"
            cursor="pointer"
          />
        </Box>
        <Stack spacing="12px" flex="1" paddingX="20px" >
          <Box
            style={{width: '95%'}}
            onClick={() => {
              if (typeof modalNavigationProps !== 'undefined') {
                onToggle()
                modalNavigationProps.history.push(productLink)
                modalNavigationProps.searchRef?.current?.setQuery('')
              } else {
                navigate(productLink)
              }
  
              sendViewProduct({product: {id: product.id, sku: ''}})
            }}
          >
            <Text
              color="gray800"
              flex="1"
              flexGrow="0"
              lineHeight="normal"
              overflow="hidden"
              variant="text4"
              cursor="pointer"
            >
              {product.name}
            </Text>
          </Box>
          <Box alignSelf="flex-start">
            {product.productRating ? (
              <ReviewStars rating={product.productRating} />
            ) : (
              <Box height="20px" display={['none', 'block']} />
            )}
          </Box>
        </Stack>
        <TopRightProductBadgeGroup
          hit={hit}
          className={promotionModalTopRightBadges}
          position='relative'
        />
        <Flex flexShrink="0" justify="space-between" width="full">
          <Price price={displayPrice} promotionPrice={promotionPrice} priceInfo={product.priceInfo} spacing="12px" />
          {showAddToBasket ? (
            product.isAddToCartDisabled ? (
              <>
                <Button disabled variant="secondary" marginTop="16px" style={{width: 145}}>
                  {getDisabledButtonText(product.disabledStatus)}
                </Button>
              </>
            ) : (
              <UpdateCartItemQuantity
                product={hit}
                quantity={clickedQuantity || 0}
                setQuantity={setClickedQuantity}
                maxQuantity={product.maxOrderQuantity}
                marginTop="16px"
                cartLocation={CART_LOCATION.PROMO}
                renderButton={
                  <ATBButton
                    hit={hit}
                    displayPrice={displayPrice}
                    onClick={(e) => {
                      addToBasket(e)
                      sendAddToCartData(hit, CART_LOCATION.PROMO)
                      globalStore.setShowCheckoutSummary(true)
                    }}
                    style={{width: 145}}
                    marginTop="16px"
                  />
                }
              />
            )
          ) : null}
        </Flex>
      </Box>
    )
  },
)

const ProductTileCompactContainer: React.FC<Omit<ProductTileProps, 'product'>> = ({
  productId,
  showAddToBasket = true,
  quantity,
  onToggle,
  modalNavigationProps
}) => {
  const productStore = useProductStore()
  const product = productStore.productsById[productId]

  if (product == null) {
    return null
  }

  return <ProductTile product={product} showAddToBasket={showAddToBasket} quantity={quantity} onToggle={onToggle} modalNavigationProps={modalNavigationProps} />
}

export const ProductTileCompact = observer(ProductTileCompactContainer)

export const ProductTileCompactSkeleton = () => {
  return (
    <Box
      bg="white"
      borderBottom={['1px', '0px']}
      borderColor="gray200"
      className={promotionProductTile}
      display="flex"
      flexWrap="wrap"
      height="full"
      position="relative"
      width="full"
    >
      <Box display="block" height="80px" width="80px" bg="gray100" />
      <Stack spacing="12px" flex="1" paddingX="20px" justify="space-between">
        <Stack spacing="4px">
          <Box display="block" height="20px" style={{width: '90%'}} bg="gray100" />
          <Box display="block" height="20px" width="48px" bg="gray100" />
        </Stack>
        <Box display="block" height="20px" width="100px" bg="gray100" />
      </Stack>
      <Flex flexShrink="0" justify="space-between" width="full" marginTop="16px">
        <Box display="block" height="20px" width="72px" bg="gray100" />
      </Flex>
    </Box>
  )
}
