import "app/components/header/styles.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"app/components/header/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81Wy47jIBC85yuQ9hIfiGLnMYlz2T8ZYSAxCgEL43Emq/z7YoizxiEPa2akPVmC6u7q7urGk/d4n/C9SKZpLj+oAn9GAGDJpUrBB1JjCN/jVf22zOpkHW3MnaZHDQnFUiHNpEhBJQhVnAm6GZ1Hk9ZdnAqpx+mWqVJDnDNOIus6k8rAIadbc+zCaIVEWSBFhfZcJNbA3jIXCnEOkun0UDZETpCZyMcUxJ7VzGVQqbLxXUgmNFUNPkN4v1PS0IXh/GaR52gedtRFdEo21PvCWm2l0LCmbJfrvsFM+AbLe3mFw6Ge9fd29806IqwsOPpMwZbTY2PdfCFhimJnrGTdHCPOdgIyTQ9lCjBtiReIECZ2Vgt9Oruoi9Cy6AMyD5BJreUhhOmSHtKu2Lddfan6q07kRy0vopeEeuGWU9SMEs4p3stKw6wyRRAuVGp6gzJOyf2Ox6c70X4tMJ5nay+DtfWDioIiM4+YpkDIRhMAVKXhUFJuev7vsM5Nt6EZaoesFSo2j2Y5UFhbp5Kd6E2VaLR5UkduEY1qYR5GVC53t4wUIqwq+5i502C4Ba91qi2xDXPjn3Q5hMPMuxAncrc6W8rT4thBmDl5dO0GzZ51W/tsNdwVSqAmnt+QBq/iAkAaeTD92V/g6Fukdt1NTFgdtCtqiAQv28UQvNTxf9HkbSu8N/TRVvqqGruNGvr8PXG/6Lv39HNVy3SyXASf4QFva0+qs+G2vw+UMARKrCgVAAkCxgcmYM2IzhvBJPPi6P54OgnZXyKO/D+i/nyrJmp/gr0Rv4NoH0PlaPct/ePzE2bXu+xHScZ3WAbF+dKyPI/OfwElWvre1AoAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var checkoutButton = '_1k2lkn29';
export var linkUtilityMenu = '_1k2lkn20';
export var megaMenuInternalItem = '_1k2lkn24';
export var megaMenuInternalItemActive = '_1k2lkn23';
export var megaMenuInternalItemText = '_1k2lkn25';
export var megaMenuListItem = '_1k2lkn21';
export var megaMenuSection = '_1k2lkn22';
export var mobileNavigationMenuBackLink = '_1k2lkn27';
export var mobileNavigationMenuListItem = '_1k2lkn28';
export var mobileNavigationMenuListItemRoot = '_1k2lkn26';
export var viewBasketButton = '_1k2lkn2a';
export var viewBasketContent = '_1k2lkn2b';