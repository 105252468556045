import React from "react"
import {Button, ButtonProps, Text} from "../../vanilla"
import {Nullable} from "../../types/utils"
import {Hit} from "../../store/ProductStore"
import {getDisabledButtonText} from "../../utils/utils"

interface AddToBasketButtonProps extends ButtonProps {
    hit: Hit,
    displayPrice: Nullable<number>
    isPLPTile?: boolean
    isPDPPage?: boolean
}

export const ATBButton = React.forwardRef((props: AddToBasketButtonProps, ref: React.Ref<any>) => {
    const { 
        hit,
        displayPrice,
        isPLPTile,
        isPDPPage,
        ...rest 
    } = props

    const isButtonDisabled = !displayPrice 
                            || !hit.availableForDayAtStore 
                            || hit.isAddToCartDisabled === true 



    return (
        <Button 
        variant={!isButtonDisabled ? "primary" : "secondary"} 
        disabled={isButtonDisabled}
        size={(isPDPPage || isPLPTile) ? "smWide" : "sm"}
        paddingY={(isPDPPage || isPLPTile) ? "8px" : "0px"}
        {...rest}
        ref={ref}>
            <Text variant="unstyled" fontSize={isPDPPage ? "sm" : "inherit"} display={isPLPTile ? ['none', 'block'] : 'block'}>{!isButtonDisabled ? 'Add to Basket' : getDisabledButtonText(hit.disabledStatus)}</Text>
            <Text variant="unstyled" fontSize={isPLPTile ? "sm" : "inherit"} display={isPLPTile ? ['block', 'none'] : 'none'}>
                {!isButtonDisabled ? 'Add' : getDisabledButtonText(hit.disabledStatus)}
            </Text>
        </Button>
    )
})
