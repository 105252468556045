import React, {MouseEvent, useEffect, useState} from 'react'
import {NavItem} from '../../../store/ContentStore'
import {Box, Flex, Stack, Text} from '../../../vanilla'
import {
  mobileNavigationMenuBackLink,
  mobileNavigationMenuListItem,
  mobileNavigationMenuListItemRoot,
} from '../styles.css'
import {Link} from '../../link'
import {mobileNavIconsMap} from './mobile-navigation-icon-map'
import {ChevronLeftIcon, ChevronRightIcon} from '../../icons'
import {NavFlyoutBanner} from '../../cms/NavFlyoutBanners'
import {DRAWER_WIDTH, TRANSITION_DURATION} from './index'
import {
  useNavMenuPromoBannersDataTracking,
  useNavMenuPromotionDataTracking,
  useSelectNavMenuPromotionTracking,
} from '../../../analytics/promotion-tracking'
import {vars} from '../../../vanilla/vars.css'
import {isMobile} from '../../../utils/utils'

interface MobileNavigationListProps {
  nav: Array<NavItem>
  onClose: () => void
}

export const MobileNavigationList = ({nav, onClose}: MobileNavigationListProps) => {
  const sendNavMenuPromotionData = useNavMenuPromotionDataTracking()
  const isMobileFlag: boolean = isMobile()

  useEffect(() => {
    if (isMobileFlag) {
      sendNavMenuPromotionData(nav,'Mobile Navigation Top Level', false)
    }
  }, [])
  return (
    <Stack as="ul" bg="white" spacing="0px">
      {nav?.map(({name, children, color, icon, path, categoryId}, index) => (
        <MobileNavigationListItem
          key={name}
          name={name}
          path={path}
          categoryId={categoryId}
          childrenNodes={children}
          icon={icon}
          root
          onClose={onClose}
          index={index + 1}
        />
      ))}
    </Stack>
  )
}

interface MobileNavigationListItemProps extends Partial<NavItem> {
  childrenNodes?: Array<NavItem>
  root?: boolean
  onClose: () => void
  level?: number,
  index?: number
}

export const MobileNavigationListItem = (props: MobileNavigationListItemProps) => {
  const {
    name,
    childrenNodes,
    path,
    categoryId,
    icon,
    root,
    flyOutContent,
    onClose,
    level = 1,
    index
  } = props

  const [isExpandedItem, setIsExpandedItem] = useState<boolean>(false)

  const sendNavMenuPromotionData = useNavMenuPromotionDataTracking()
  const sendNavMenuPromoBannersData = useNavMenuPromoBannersDataTracking()
  const sendNavMenuSelectPromotion = useSelectNavMenuPromotionTracking()

  const resetScrollToTop = () => {
    const scrollContainer = document.querySelector('#mobile-nav-stack')
    if (scrollContainer) {
      scrollContainer.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
  }

  return (
    <Stack
      as="li"
      direction="row"
      borderBottom="1px"
      justify="space-between"
      alignItems="center"
      width="full"
      style={{
        borderBottom: '1px solid #f3f3f3',
        WebkitTapHighlightColor: 'transparent'
      }}
    >
      <Stack
        direction="row"
        paddingY="4px"
        minHeight="40px"
        paddingLeft="16px"
        paddingRight="12px"
        alignItems="center"
        width="full"
        className={root ? mobileNavigationMenuListItemRoot : mobileNavigationMenuListItem}
        justify="space-between"
        {...(!path
          ? {
              onClick: (e: MouseEvent<HTMLElement>) => {
                e.stopPropagation()
                resetScrollToTop()
                setIsExpandedItem((prev) => !prev)
                  if (!isExpandedItem) {
                    sendNavMenuSelectPromotion(
                    name,
                    path ? path : 'Nav folder',
                    categoryId,
                    level === 1 ? 'Mobile Navigation Top Level' : `Mobile Navigation Level ${level - 1}`,
                    level > 1 ? childrenNodes?.[0]?.categoryId : 'No category ID',
                    index
                )
                  flyOutContent && sendNavMenuPromoBannersData(flyOutContent)
                  sendNavMenuPromotionData(childrenNodes, `Mobile Navigation Level ${level}`, true)
                } else {
                  onClose()
                }
              },
            }
          : {
              to: path,
              as: Link,
              onClick: onClose,
              onClickCapture: () => {
                sendNavMenuSelectPromotion(
                  name,
                  path,
                  categoryId,
                  level === 1 ? 'Mobile Navigation Top Level' : `Mobile Navigation Level ${level - 1}`,
                  childrenNodes?.[0]?.categoryId,
                  index
                )
                onClose()
              },
            })}
      >
        <Flex align="center" gap="8px" as="span">
          {icon ? mobileNavIconsMap[icon] : null}{' '}
          <Text
            fontWeight="medium"
            variant="unstyled"
            lineHeight="shorter"
            as="span"
            style={{fontSize: 15}}
          >
            {name}
          </Text>
        </Flex>
        {!path && <ChevronRightIcon boxSize="16px" />}
        {!path && (
          <Box
            position="absolute"
            top="0px"
            left="0px"
            width="full"
            minHeight="full"
            backgroundColor="white"
            display={root && !isExpandedItem ? 'none' : 'block'}
            style={{
              transition: `transform ${TRANSITION_DURATION}ms ease-in`,
              transform: `translateX(${isExpandedItem ? 0 : -DRAWER_WIDTH}px)`,
              zIndex: vars.zIndex.mobileHeader
            }}
            maxWidth={'275px'}
          >
            <Stack
              spacing="8px"
              minHeight="40px"
              className={mobileNavigationMenuBackLink}
              onClick={(e: MouseEvent<HTMLElement>) => {
                e.stopPropagation()
                setIsExpandedItem(false)
              }}
            >
              <ChevronLeftIcon boxSize="16px" />
              <Text
                variant="unstyled"
                fontSize="lg"
                lineHeight="short"
                fontWeight="medium"
                as="span"
              >
                Back
              </Text>
            </Stack>
            <Stack
              paddingX="16px"
              style={{backgroundColor: '#f3f3f3', cursor: 'initial'}}
              minHeight="52px"
              alignItems="center"
              direction="row"
            >
              <Text variant="unstyled" fontSize="lg" lineHeight="tall">
                {name}
              </Text>
            </Stack>
            <Stack as="ul" spacing="0px">
              {childrenNodes?.map(
                ({name: childrenName, path, children, flyOutContent, categoryId}, index) => (
                  <MobileNavigationListItem
                    key={`${childrenName}-${name}`}
                    name={childrenName}
                    path={path}
                    categoryId={categoryId}
                    childrenNodes={children}
                    flyOutContent={flyOutContent}
                    onClose={onClose}
                    level={level + 1}
                    index={index + 1}
                  />
                ),
              )}
            </Stack>

            {flyOutContent && (
              <Stack>
                {flyOutContent.default?.map((content, index) => (
                  <NavFlyoutBanner
                    key={index}
                    content={content as any}
                    titleStyles={{
                      fontSize: '1.25rem',
                      lineHeight: '1.6',
                      color: 'inherit',
                      minHeight: 52,
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      padding: '4px 16px',
                      fontWeight: 400,
                      backgroundColor: '#f3f3f3',
                    }}
                  />
                ))}
              </Stack>
            )}
          </Box>
        )}
      </Stack>
    </Stack>
  )
}
