import React, {useEffect, useMemo, useState} from 'react'
import {AccordionList} from '../../types/cms'
import {SlotComponent} from '../../types/ui'
import {Box, BoxProps, Flex, Stack, Text} from '../../vanilla'
import {ChevronDownIcon, ChevronUpIcon, MinusIcon, PlusIcon} from '../icons'
import {Nullable} from '../../types/utils'
import {Sprinkles} from '../../vanilla/sprinkles.css'
import {CollapsablePanelStateless} from '../collapsable-panel-stateless'
import {Markdown} from './Markdown'

interface ContentAccordionComponentProps {
  headingProps?: BoxProps<React.ElementType>
  accordionHeadingProps?: BoxProps<React.ElementType>
  accordionContentProps?: BoxProps<React.ElementType>
}

export const ContentAccordion: SlotComponent<AccordionList, ContentAccordionComponentProps> = ({
  content,
  headingProps,
  accordionHeadingProps,
  accordionContentProps,
}) => {
  const {headingAlignment, iconAlignment, heading, subHeadingText, accordionList, anchorID, iconType = 'chevron'} =
    content

  const textAlign = (headingAlignment || 'left').toLowerCase() as Sprinkles['textAlign']

  const [selectedAccordionId, setSelectedAccordionId] = useState<Nullable<string>>(null)

  const accordions = useMemo(() => {
    return (
      accordionList?.map((accordion) => {
        if (accordion.accordionID) {
          return accordion
        }

        return {
          ...accordion,
          accordionID: `accordion-item-${Math.floor(Math.random() * Date.now())}`,
        }
      }) || []
    )
  }, [accordionList])

  const onToggle = (accordionId: string) => {
    setSelectedAccordionId(accordionId === selectedAccordionId ? null : accordionId)
  }

  const onOpen = (accordionId: string) => {
    setSelectedAccordionId(accordionId)
  }

  const onClose = () => {
    setSelectedAccordionId(null)
  }

  const Icon = (isOpen: boolean) => {
    if (iconType === 'plus') {
      return isOpen ? (
        <MinusIcon boxSize="16px" color="gray800" />
      ) : (
        <PlusIcon boxSize="16px" color="gray800" />
      )
    }

    if (iconType === 'chevron') {
      return isOpen ? (
        <ChevronUpIcon boxSize="16px" color="gray800" />
      ) : (
        <ChevronDownIcon boxSize="16px" color="gray800" />
      )
    }
  }

  useEffect(() => {
    if (accordions?.length) {
      const defaultOpened = accordions.find((accordion) => accordion.isAccordianOpen)

      if (defaultOpened?.accordionID) {
        setSelectedAccordionId(defaultOpened.accordionID)
      }
    }
  }, [accordions])

  if (!content) {
    return null
  }

  return (
    <Stack spacing="0px" id={anchorID ?? null}>
      {(heading || subHeadingText) && (
        <Stack spacing="16px" paddingBottom="16px" {...(headingProps || {})}>
          {heading && (
            <Text variant="heading0" textAlign={textAlign}>
              {heading}
            </Text>
          )}
          {subHeadingText && (
            <Text variant="text4" textAlign={textAlign} color="gray800">
              {subHeadingText}
            </Text>
          )}
        </Stack>
      )}

      <Box>
        {accordions.map(({isAccordianOpen, accordionText, title, accordionID}, index) => (
          <CollapsablePanelStateless
            isOpen={selectedAccordionId === accordionID}
            key={accordionID}
            onClose={onClose}
            onOpen={() => onOpen(accordionID)}
            onToggle={() => onToggle(accordionID)}
            maxHeight="fit"
            borderBottom="1px"
            borderTop={index === 0 ? '1px' : 'none'}
            style={{borderColor: '#d9d9d9'}}
            // @ts-ignore
            content={
              <Box
                {...(iconAlignment === 'Left' ? {padding: '20px'} : {paddingBottom: '20px'})}
                {...(accordionContentProps || {})}
              >
                <Markdown
                  markdown={(accordionText.richText || '') as string}
                  componentProps={{
                    p: {variant: 'text3', lineHeight: 'short'},
                  }}
                />
              </Box>
            }
          >
            {({onToggle, isOpen}) => {
              return (
                <Flex
                  cursor="pointer"
                  borderRadius="none"
                  justifyContent={iconAlignment === 'Left' ? 'flex-end' : 'space-between'}
                  height="auto"
                  width="full"
                  alignItems="center"
                  onClick={() => onToggle()}
                  paddingY="16px"
                  direction={iconAlignment === 'Left' ? 'row-reverse' : 'row'}
                  gap="20px"
                  paddingLeft={iconAlignment === 'Left' ? '16px' : '28px'}
                  {...(accordionHeadingProps || {})}
                >
                  <Text variant="unstyled" fontWeight="normal" fontSize="md">
                    {title}
                  </Text>
                  <Box justifySelf={iconAlignment === 'Left' ? 'flex-start' : 'flex-end'}>
                    {Icon(isOpen)}
                  </Box>
                </Flex>
              )
            }}
          </CollapsablePanelStateless>
        ))}
      </Box>
    </Stack>
  )
}
