import React, {useState, useEffect} from 'react'
import {Box} from '../../vanilla'
import {TopRightProductBadge} from '../cms/product-badges'
import {useGlobalStore} from '../../store/hooks/useStore'
import {Hit} from '../../store/ProductStore'

interface TopRightProductBadgeGroupProps {
  hit: Hit
  className?: string
  top?: Array<number>
  right?: number
  position?: ("fixed" | "absolute" | "relative" | "static" | "sticky")
}

export const TopRightProductBadgeGroup = ({
  hit,
  className,
  top,
  right,
  position
}: TopRightProductBadgeGroupProps) => {
  
  const globalStore = useGlobalStore()
  
  // Sets current custom site preferences for three badges in state
  const [pdpTopRightTileBadgeEnabled, setPdpTopRightTileBadgeEnabled] = useState(false)
  const [pdpMiddleRightTileBadgeEnabled, setPdpMiddleRightTileBadgeEnabled] = useState(false)
  const [pdpBottomRightTileBadgeEnabled, setPdpBottomRightTileBadgeEnabled] = useState(false)

  // Updates state with current custom site preferences for three badges
  useEffect(() => {
    setPdpTopRightTileBadgeEnabled(globalStore.badgesCustomPreferences?.topRightBadgeCustomPreference as boolean)
    setPdpMiddleRightTileBadgeEnabled(globalStore.badgesCustomPreferences?.middleRightBadgeCustomPreference as boolean)
    setPdpBottomRightTileBadgeEnabled(globalStore.badgesCustomPreferences?.bottomRightBadgeCustomPreference as boolean)
  }, [])

  const badgeOnProduct = pdpTopRightTileBadgeEnabled && hit.pdpTopRightTileBadge || pdpMiddleRightTileBadgeEnabled && hit.pdpMiddleRightTileBadge || pdpBottomRightTileBadgeEnabled && hit.pdpBottomRightTileBadge

  if (!badgeOnProduct) return null

    return (
        <Box className={className ?? ''}>
            {pdpTopRightTileBadgeEnabled && hit.pdpTopRightTileBadge ? (
              <TopRightProductBadge
                badgeKey={hit.pdpTopRightTileBadge}
                top={top?.[0]}
                right={right}
                zIndex="badge"
                {...position ? {position} : {}}
              />
            ) : null}
            {pdpMiddleRightTileBadgeEnabled && hit.pdpMiddleRightTileBadge ? (
              <TopRightProductBadge
                badgeKey={hit.pdpMiddleRightTileBadge}
                top={top?.[1]}
                right={right}
                zIndex="badge"
                {...position ? {position} : {}}
              />
            ) : null}
            {pdpBottomRightTileBadgeEnabled && hit.pdpBottomRightTileBadge ? (
              <TopRightProductBadge
                badgeKey={hit.pdpBottomRightTileBadge}
                top={top?.[2]}
                right={right}
                zIndex="badge"
                {...position ? {position} : {}}
              />
            ) : null}
          </Box>
    )
}