import {useProductStore, usePromotionStore} from '../../store/hooks/useStore'
import {useEffect, useState} from 'react'
import {flowResult, toJS} from 'mobx'
import {Nullable} from '../../types/utils'
import {PromotionResponse} from '../../store/PromotionStore'
import {useSearchParams} from '../../hooks/use-search-params'

const PROMO_QUERY_NAME = 'promo'
export const useMealDeals = () => {
  const searchParams = useSearchParams()
  const productStore = useProductStore()
  const promotionStore = usePromotionStore()
  const [mealDeals, setMealDeals] = useState<Nullable<PromotionResponse>>(null)
  const [status, setStatus] = useState<'initial' | 'loading' | 'done'>('initial')

  useEffect(() => {
    const promoName = searchParams.get(PROMO_QUERY_NAME)
    if (promoName) {
      setStatus('loading')
      flowResult(promotionStore.fetchPromotionsByIds([promoName])).then(() => {
        const res = toJS(promotionStore.promotions?.[promoName])
        if (res) {
          setMealDeals(res as unknown as PromotionResponse)
          if (res?.productIds?.length) {
            productStore.fetchProducts(res.productIds, false)
          }
        }
        setStatus('done')
      })
    }
  }, [productStore, promotionStore, searchParams])

  return {mealDeals, status}
}
